import { useEffect, useState } from "react";
// for work_images
import mt1 from "../assets/images/work_images/mt1.png";
import mt2 from "../assets/images/work_images/mt2.png";
import mt3 from "../assets/images/work_images/mt3.png";
import mt4 from "../assets/images/work_images/mt4.png";
import mt5 from "../assets/images/work_images/mt5.png";
import tb1 from "../assets/images/work_images/tb1.png";
import tb2 from "../assets/images/work_images/tb2.png";
import tb3 from "../assets/images/work_images/tb3.png";
import food1 from "../assets/images/work_images/food1.png";
import food2 from "../assets/images/work_images/food2.png";
import food2b from "../assets/images/work_images/food2b.png";
import food3 from "../assets/images/work_images/food3.png";
import food4 from "../assets/images/work_images/food4.png";
import loki1 from "../assets/images/work_images/loki1.png";
import loki2 from "../assets/images/work_images/loki2.png";
import loki3 from "../assets/images/work_images/loki3.png";
import loki4 from "../assets/images/work_images/loki4.png";
import buki1 from "../assets/images/work_images/buki1.png";
import buki2 from "../assets/images/work_images/buki2.png";
import buki3 from "../assets/images/work_images/buki3.png";
import buki4 from "../assets/images/work_images/buki4.png";
import buki5 from "../assets/images/work_images/buki5.png";
import buki6 from "../assets/images/work_images/buki6.png";
import cakes1 from "../assets/images/work_images/cakes1.png";
import cakes2 from "../assets/images/work_images/cakes2.png";
import cakes3 from "../assets/images/work_images/cakes3.png";
import cakes4 from "../assets/images/work_images/cakes4.png";
import cakes5 from "../assets/images/work_images/cakes5.png";
import cakes6 from "../assets/images/work_images/cakes6.png";
import puzzle1 from "../assets/images/work_images/puzzle1.png";
import puzzle2 from "../assets/images/work_images/puzzle2.png";
import puzzle3 from "../assets/images/work_images/puzzle3.png";

import img1 from "../assets/images/slider/react-svgrepo-com.svg";
import img2 from "../assets/images/slider/javascript-svgrepo-com.svg";
import img3 from "../assets/images/slider/redux-svgrepo-com.svg";
import img4 from "../assets/images/slider/nodejs-icon-logo-svgrepo-com.svg";
import img5 from "../assets/images/slider/typescript-official-svgrepo-com.svg";
import img6 from "../assets/images/slider/java-4-logo-svgrepo-com.svg";
import img7 from "../assets/images/slider/tailwind-svgrepo-com.svg";
import img8 from "../assets/images/slider/bootstrap-fill-svgrepo-com.svg";
import img9 from "../assets/images/slider/material-ui-svgrepo-com.svg";
import img10 from "../assets/images/slider/sass-svgrepo-com.svg";
import img11 from "../assets/images/slider/prettier-svgrepo-com.svg";
import img12 from "../assets/images/slider/eslint2-svgrepo-com.svg";
import img13 from "../assets/images/slider/sql-database-generic-svgrepo-com.svg"; //
import img14 from "../assets/images/slider/mongodb-svgrepo-com.svg";
import img15 from "../assets/images/slider/postgresql-svgrepo-com.svg";
import img16 from "../assets/images/slider/aws-svgrepo-com.svg";
import img17 from "../assets/images/slider/azure-svgrepo-com.svg";
import img18 from "../assets/images/slider/google-cloud-svgrepo-com.svg";
import img19 from "../assets/images/slider/vs-code-svgrepo-com.svg";
import img20 from "../assets/images/slider/github-142-svgrepo-com.svg";
import img21 from "../assets/images/slider/gitlab-svgrepo-com.svg";
import img22 from "../assets/images/slider/git-svgrepo-com.svg";
import img23 from "../assets/images/slider/jenkins-svgrepo-com.svg";
import img24 from "../assets/images/slider/trello-color-svgrepo-com.svg"; //
import img25 from "../assets/images/slider/jira-svgrepo-com.svg";
import img26 from "../assets/images/slider/adobe-after-effects-svgrepo-com.svg";
import img27 from "../assets/images/slider/adobe-premiere-svgrepo-com.svg";
import img28 from "../assets/images/slider/adobe-photoshop-svgrepo-com.svg";
import img29 from "../assets/images/slider/dotnet-svgrepo-com.svg";
import img30 from "../assets/images/slider/nextjs-svgrepo-com.svg";

//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
import iconWebApp from "../assets/images/icons/web-application-svgrepo-com.svg";
import iconVideoEdition from "../assets/images/icons/video-edition-svgrepo-com.svg";
import iconSpecialEffects from "../assets/images/icons/special-effects-svgrepo-com.svg";
// contact image
import { CgNotes } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
// import { FaBlogger, FaRegUser } from "react-icons/fa";
// import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
// import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items from experience  modal open
  const handleExperienceModalData = (id) => {
    const find =
      experiencesArray.find((item) => item?.id === id) ||
      sideProjectsArray.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  // const handleBlogsData = (id) => {
  //   const find = blogsData.find((item) => item?.id === id);
  //   setSingleData(find);
  //   setIsOpen(true);
  // };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section

  // Web: UMT, Timebet, Portfolio?, Next Demo Food, App Quiz?
  // Video: Loki, YouTube, Sameer, Scott Rodgers, Cakes?, Terry?, Ezzy?

  const workItems = [
    {
      id: "1",
      tag: "Web",
      title: "Message Tracker",
      images: [mt1, mt2, mt3, mt4, mt5],
      imgSmall: mt1,
      bg: "#FFF0F0",
      langages:
        "React, Router, TanStack Query, Charts.js, Tailwind CSS, Node.js, Express",
      link: "",
      linkText: "",
      github: "",
      description:
        "This project was developed to address a client's issue by providing observability, tracking, and analytics for thousands of SMS, email, and push notification communications processed through their backend system. The solution features a front end built with React, React Router, TanStack Query, Charts.js, and Tailwind CSS. Additionally, a mock backend was implemented to generate random data using Node.js and Express.",
    },
    {
      id: "2",
      tag: "Web",
      title: "TimerBet Desktop",
      images: [tb1, tb2, tb3],
      imgSmall: tb1,
      bg: "#FFF0F0",
      langages: "Electron.js, JavaScript, AJAX",
      link: "",
      linkText: "",
      github: "",
      description:
        "A client commissioned me to develop a desktop version of the betting website www.timerbet.com. The program was designed to run locally on multiple computers, requiring extensive customization and the creation of automatic scripts to operate on top of the website's default version. The project was built using Electron to facilitate local execution of the web application, with modifications made using JavaScript, pure CSS, and Ajax.",
    },
    {
      id: "3",
      tag: "Web",
      title: "Food Community",
      images: [food1, food2, food2b, food3, food4],
      imgSmall: food1,
      bg: "#FFF0F0",
      langages: "React, Next.js, SQLite, CSS",
      link: "",
      linkText: "",
      github: "https://github.com/juanmalvarezvaa/next-demo-food",
      description:
        "This demo website was created for a Food Community, enabling users to add, share, and explore recipes. It was developed using React, Next.js, and pure CSS.",
    },
    {
      id: "4",
      tag: "Web",
      title: "Puzzle",
      images: [puzzle1, puzzle2, puzzle3],
      imgSmall: puzzle2,
      bg: "#FFF0F0",
      langages: "React, Framer, Three.js, CSS",
      link: "https://puzzle-demo-react.netlify.app/",
      linkText: "TRY IT",
      github: "https://github.com/juanmalvarezvaa/puzzle-demo",
      description:
        "This demo puzzle game was created to show smooth animations and transitions using React, Framer, and Three.js. Click on TRY IT to give it a try!",
    },
    {
      id: "5",
      tag: "Video",
      title: "Loki",
      images: [loki1, loki2, loki3, loki4],
      imgSmall: loki1,
      bg: "#FFF0F0",
      langages: "Adobe suite: Premiere Pro, After Effects, Photoshop",
      link: "",
      linkText: "",
      github: "",
      description:
        "This video was produced for a client to showcase an AI solution via WhatsApp. The project involved creating a logo, an intro, and an outro, as well as crafting an engaging narrative. Additionally, the video underwent color and audio correction, and was enriched with animations, effects, and sound effects for a dynamic presentation.",
    },
    {
      id: "6",
      tag: "Video",
      title: "Buki",
      images: [buki1, buki2, buki3, buki4, buki5, buki6],
      imgSmall: buki1,
      bg: "#FFF0F0",
      langages: "Adobe suite: Premiere Pro, After Effects, Photoshop",
      link: "",
      linkText: "",
      github: "",
      description:
        "This series comprises multiple videos produced for a YouTube channel focused on gaming, streaming, and hardware. The project included the creation of logos, intros, and outros, along with the development of an engaging narrative. Each video was enhanced through color and audio correction, and enriched with animations, effects, and sound effects to ensure a dynamic presentation. Desktop and vertical versions were developed, as well as reels and various social media content pieces.",
    },
    {
      id: "7",
      tag: "Video",
      title: "Cake's Artisan",
      images: [cakes1, cakes2, cakes3, cakes4, cakes5, cakes6],
      imgSmall: cakes1,
      bg: "#FFF0F0",
      langages: "Adobe suite: Premiere Pro, After Effects, Photoshop.",
      link: "",
      linkText: "",
      github: "",
      description:
        "This series features Instagram videos, reels, and social media content created for a bakery and cake shop initiative. The project included the creation of logos, intros, and outros, along with the development of an engaging narrative. Each video was enhanced through color and audio correction, and enriched with animations, effects, and sound effects to ensure a dynamic presentation.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    // {
    //   id: "04",
    //   name: "Contact",
    //   link: "/contact",
    //   icon: <RiContactsBookLine />,
    // },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img30,
    img4,
    img5,
    img29,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
  ];

  // serviceArray items for about page
  const serviceArray = [
    {
      id: "1",
      icon: icon5,
      title: "Web Development",
      des: "Creation of responsive appealing websites tailored to your business needs.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
    {
      id: "2",
      icon: icon,
      title: "Ui/Ux Design",
      des: "Craft intuitive and engaging interfaces to enhance user satisfaction and success.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "3",
      icon: icon1,
      title: "App Development",
      des: "Development of full stack applications, to deliver complete solutions.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "4",
      icon: iconWebApp,
      title: "Web Apps",
      des: "Creation of  dynamic, full-featured web applications for seamless user experiences.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "5",
      icon: iconVideoEdition,
      title: "Video Edition",
      des: "Creation of compelling video content with precision editing to enhance storytelling and engage viewers.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "6",
      icon: iconSpecialEffects,
      title: "Post Production",
      des: "Enhancement of videos with high-quality effects, animations and finishing touches in post-production.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "2006-2012",
      title: "Bechelor in Computer Science",
      place: "UADE, Buenos Aires, Argentina",
      bg: "#FFF4F4",
    },
    {
      id: 2,
      date: "2002",
      title: "C1 First FCE",
      place: "Cambridge English, Buenos Aires, Argentina",
      bg: "#FFF4F4",
    },
  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "Mar 2024 - Present",
      title: "Software Engineer",
      place: "Botmaker",
      bg: "#EEF5FA",
      // logo: logoTeladoc,
      companyLink: "https://botmaker.com/",
      responsabilities: [
        "Collaborate in defining, planning, and designing new features.",
        "Take ownership of implementing new features for flagship products.",
        "Mentor and conduct code reviews for colleagues.",
        "Improve and optimize the existing codebase.",
        "Contribute to both web and mobile initiatives.",
        "Work closely with design, backend, and product teams.",
        "Be a key player in resolving critical production issues.",
      ],
      keyAccomplishments: [
        "Led the development of a status page for system observability, utilizing Node, Express, React, TypeScript, and SASS.",
        "Implemented multiple features to enhance the main product, using React, TypeScript, Next.js, React Native, Node.js, Express, SASS, Material UI, pure CSS, and Figma.",
        "Redesigned and refactored core functionalities of the flagship product.",
      ],
    },
    {
      id: 3,
      date: "Jan 2017 - Apr 2022 · 5 yrs 4 mos",
      title: "Full Stack Software Engineer",
      place: "Banco de la Nación Argentina",
      bg: "#EEF5FA",
      // logo: logoTeladoc,
      companyLink: "https://www.bna.com.ar/",
      responsabilities: [
        "Led a team of six junior developers as the second-in-command.",
        "Propose, plan, design, and develop solutions to enhance speed, security, and information in critical areas.",
        "Integrate technical interview panels.",
        "Mentorship and code review.",
        "Propose and execute enhancements to optimize database query efficiency.",
        "Relieve critical needs of key bank departments.",
      ],
      keyAccomplishments: [
        "Led the planning and development of an accounting system, developed in JavaScript and React on the front end, and C# and .Net on the back end, enabling observability, reporting, metrics, and analytics for bill processing across worldwide bank branches.",
        "Provided mentorship and onboard new developers into the organization.",
        "Enhanced operational efficiency by automating and reducing significant manual workload.",
      ],
    },
    {
      id: 4,
      date: "Jul 2012 - Jan 2017 · 4 yrs 7 mos",
      title: "Back End Software Engineer",
      place: "Banco de la Nación Argentina",
      bg: "#EEF5FA",
      companyLink: "https://www.bna.com.ar/",
      responsabilities: [
        "Modernization and enhancement of monolithic applications through migration and upgrades.",
        "Contribute design feedback and actively engage in the development of batch and backend implementations.",
        "Architect and develop extensive databases.",
        "Enhance and integrate REST APIs with various systems.",
      ],
      keyAccomplishments: [
        "Participated in the migration of COBOL/CICS applications to modern Java-based solutions.",
        "Enhanced and optimized a Microsoft SQL database for a pivotal company component.",
      ],
    },
    {
      id: 5,
      date: "Jan 2011 - Jun 2012 · 1 yr 6 mos",
      title: "Project Manager",
      place: "MRM",
      bg: "#EEF5FA",
      companyLink: "https://www.mrm.com/",
      responsabilities: [
        "Coordinate cross-functional teams to create marketing campaigns for clients in the USA.",
        "Analyze requirements and extract pertinent information for upcoming marketing campaigns.",
      ],
      keyAccomplishments: [
        "Managed an entire marketing campaign for General Motors in the United States.",
      ],
    },
    {
      id: 6,
      date: "Jun 2008 - Oct 2010 · 2 yrs 5 mos",
      title: "Mobile Game Developer",
      place: "Gameloft",
      bg: "#EEF5FA",
      companyLink: "https://www.gameloft.com/",
      responsabilities: [
        "Adapt mobile games for various cellphone platforms.",
        "Engage in collaborative game development initiatives.",
        "Explore the integration of new mobile platforms for existing games.",
      ],
      keyAccomplishments: [
        "Contributed to the porting of hundreds of mobile games in Java ME for various cellphone releases.",
      ],
    },
    {
      id: 7,
      date: "Nov 2007 - Jun 2008 · 8 mos",
      title: "Cobol Developer",
      place: "Visa",
      bg: "#EEF5FA",
      companyLink: "https://www.visa.com/",
      responsabilities: [
        "Development, improvement, upgrade and debugging of Mainframe applications.",
      ],
      keyAccomplishments: [
        "Contributed to the development and bug fixing of Mainframe applications managing thousands of messages per hour.",
      ],
    },
  ];

  // Side Projects
  const sideProjectsArray = [
    {
      id: 8,
      date: "Aug 2020 - Aug 2023 · 3 yrs 1 mo",
      title: "Streamer & Content Creator",
      place: "Freelance",
      bg: "#FFF4F4",
      companyLink: "https://www.youtube.com/@Buki9",
      description:
        "I've undertaken freelance projects as a Video Editor for clients worldwide, handling a diverse array of content, including weddings, interviews, public speaking, gaming, food, and more. My responsibilities typically involve editing raw footage, crafting compelling narratives, enhancing color and audio quality, incorporating effects, music, transitions, and designing Intros, Outros, animations, and motion graphics to deliver polished final products.",
    },
    {
      id: 9,
      date: "Aug 2018 - Dec 2022 · 4 yrs 5 mos",
      title: "Video Editor",
      place: "Freelance",
      bg: "#FFF4F4",
      companyLink: "https://www.upwork.com/freelancers/juanalvarez46/",
      description:
        "I managed a YouTube channel specializing in Hardware, Streaming, and Gaming. My content is dedicated to providing comprehensive explanations, professional insights, and tutorials aimed at enhancing one's online presence during Live Streaming. My videos range from beginner-friendly to advanced tutorials, covering topics such as improving audio quality, optimizing lighting, engaging with viewers, and elevating overall channel and streaming professionalism.\n\nIn addition to creating content, I regularly hosted live streaming sessions 2-3 times per week, where viewers could ask questions related to the video topics. These sessions also included gaming, fun moments, and general entertainment for an interactive and engaging experience",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    // blogsData,
    // handleBlogsData,
    // menuItem,
    NavLink,
    menuItemTwo,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    sideProjectsArray,
    // awardsArray,
    // lineArray,
    // contactArray,
    handleExperienceModalData,
  };
};

export default AllData;
